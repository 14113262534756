import React from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

export default function AccessToken({ onChange }: any) {
  return (
    <Item label="Access Token" name="accessToken" extra="Access token copied from contentful" required>
      <Input onChange={onChange} style={{ width: '100%' }} />
    </Item>
  );
}
