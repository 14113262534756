import { useMemo, useCallback } from 'react';
import { useEffect } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Image } from 'antd';
import Meta from 'antd/lib/card/Meta';
import isMobile from 'is-mobile';
import { get, has, values } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import LoadingModal from 'components/LoadingModal/LoadingModal';

import Logo from 'assets/metadyn.png';

import MetaMaskLogo from '../../../assets/metamask.svg';
import WalletConnectLogo from '../../../assets/walletconnect.svg';
import { AccountsLoginSelector, useDashboardApi } from '../hooks/useDashboardApi';

import styles from './Login.module.css';
import UnauthorizedAccount from './components/Forbidden';

export default function Login() {
  const history = useHistory();
  const location = useLocation<{ nextPath?: string }>();
  const dashboard = useDashboardApi();
  const wallet = useWallet();
  const { state, contents: user } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));

  const active = useMemo(() => user && wallet.status === 'connected', [wallet.status, user]);
  const hasAccess = useMemo(() => {
    return wallet.status === 'connected' &&
      wallet.account?.toLowerCase() === user?.user?.address &&
      has(user?.user, 'permissions')
      ? !!values(get(user?.user, 'permissions', [])).filter(Boolean).length
      : null;
  }, [user, wallet.status, wallet.account]);

  useEffect(() => {
    if (!hasAccess) {
      dashboard.signToken();
    } else {
      history.replace(location.state?.nextPath || '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.account, hasAccess]);

  const connectMetamask = useCallback(async () => {
    wallet.connect('injected');
    if (wallet.status === 'connected') {
      dashboard.signToken();
    }
  }, [wallet, dashboard]);

  const walletconnect = useCallback(() => (active ? null : wallet.connect('walletconnect')), [active, wallet]);

  const metamask = useMemo(() => {
    const actions = [
      <Button
        type="text"
        size="large"
        className={styles.walletButton}
        href="https://metamask.io/download.html"
        target="_blank"
        rel="noreferrer">
        Create Wallet
      </Button>,
    ];

    // @ts-ignore
    if (window.web3) {
      actions.unshift(
        <Button type="text" size="large" className={styles.walletButton} onClick={connectMetamask}>
          Connect
        </Button>
      );
    }

    return isMobile() === false ? (
      <Card
        className={styles.walletCard}
        style={{ width: 300 }}
        cover={<img height="180" alt="example" src={MetaMaskLogo} />}
        actions={actions}>
        <Meta title="MetaMask" description="Connect or Create a wallet with MetaMask" />
      </Card>
    ) : null;
  }, [connectMetamask]);

  const signin = useMemo(() => {
    return hasAccess && !dashboard.jwtToken ? (
      <Card
        className={styles.walletCard}
        style={{ width: 300 }}
        cover={<FormOutlined style={{ fontSize: '64px', color: '#950F7F', marginTop: 20 }} />}
        actions={[
          <Button
            loading={state === 'loading'}
            type="text"
            size="large"
            className={styles.walletButton}
            onClick={() => dashboard.signToken()}>
            Sign in
          </Button>,
        ]}>
        <Meta title="Sign in" description="Please sign in to access the platform" />
      </Card>
    ) : null;
  }, [hasAccess, dashboard.isLoading, dashboard.jwtToken]);

  const cards = useMemo(() => {
    return signin
      ? [signin]
      : [
          metamask,
          <Card
            className={styles.walletCard}
            cover={<img height="180" alt="example" src={WalletConnectLogo} />}
            actions={[
              <Button type="text" size="large" className={styles.walletButton} onClick={walletconnect}>
                Connect
              </Button>,
              <Button
                type="text"
                size="large"
                className={styles.walletButton}
                href="https://registry.walletconnect.org/"
                target="_blank"
                rel="noreferrer">
                Create Wallet
              </Button>,
            ]}>
            <Meta title="WalletConnect" description="Connect wallet with WalletConnect or Create Wallet" />
          </Card>,
        ];
  }, [signin, metamask, walletconnect]);

  return (
    <div className={styles.walletContainer}>
      <Row align="middle" justify="center" className="pt-3">
        <Col style={{ textAlign: 'center' }}>
          <Image preview={false} src={Logo} width="30%" />
        </Col>
        {/* <button onClick={() => sign()}>SIGN</button> */}
      </Row>

      <div className={styles.walletInnerContainer}>
        <UnauthorizedAccount account={wallet.account} isAdmin={hasAccess} />

        {cards}
      </div>

      <LoadingModal visible={dashboard.isLoading || state === 'loading'} />
    </div>
  );
}
