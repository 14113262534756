import { useCallback, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import JWT from 'jwt-decode';
import { atom, selector, selectorFamily } from 'recoil';
import { useWallet } from 'use-wallet';

import { TokenInfo, User } from '../types/DashboardTypes';

export function useDashboardApi() {
  const endpoint = process.env.REACT_APP_AWS_ENDPOINT;
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('token'));
  const jwt = jwtToken ? (JWT(jwtToken) as TokenInfo) : null;
  const [tokenInfo, setTokenInfo] = useState(jwt);
  const wallet = useWallet();
  const [isLoading, setIsLoading] = useState(false);

  const reset = () => {
    sessionStorage.removeItem('token');
    setJwtToken(null);
    setTokenInfo(null);
    return;
  };

  const isExpired = useCallback(() => {
    const expirationDate = tokenInfo?.exp;
    const currentDate = Math.floor(Date.now() / 1000);
    if (wallet.status === 'disconnected' || !jwtToken || !tokenInfo || currentDate > expirationDate!) {
      showModal();
      return true;
    }
    return false;
  }, [tokenInfo]);

  const authHeader = () => {
    return {
      Authorization: `Bearer ${jwtToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  };

  const signToken = useCallback(async () => {
    if (wallet.status !== 'connected' || (wallet.account && jwtToken && !isExpired())) {
      return jwtToken;
    }

    const timestamp = Math.floor(Date.now() / 1000);
    const message = `Please sign message to login to Metadyn Dashboard\n\nRequest id: ${timestamp}`;

    try {
      setIsLoading(true);
      const signature = await wallet?.ethereum?.request({
        method: 'personal_sign',
        params: [message, wallet.account],
      });

      const response = fetch(`${endpoint}/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ signature: signature, timestamp, address: wallet.account }),
      });
      const result = await response;
      if (!result.ok) {
        return reset();
      }

      const token = JSON.parse(await result?.text());
      setJwtToken(token.token);
      const decode = JWT(token.token) as TokenInfo;
      decode.user = {
        organization: token.organization,
        user: token.user,
      };

      setTokenInfo(decode);
      sessionStorage.setItem('token', token.token);
      return token;
    } catch (error) {
      console.error(error);
      return reset();
    } finally {
      setIsLoading(false);
    }
  }, [wallet.account, wallet.status, jwtToken, isExpired]);

  const getUserInfo = useCallback(async () => {
    setIsLoading(true);
    return selector({
      key: 'accounts.login',
      get: () => async () => {
        const response = await fetchDashboard('GET', '/me');
        const result = await response?.json();
        if (result) {
          setIsLoading(false);
          return result.data;
        }
        return null;
      },
    });
  }, []);

  /**
   * Method to fetch Dashboard API.
   * @param method HTTP Method to execute
   * @param urlSuffix Suffix of your url ex: /createorganization
   * @param body Request body, should be a json
   * @return If the session has expired, it will return to the login screen, otherwise it will return the response
   */
  const fetchDashboard = async (method: 'POST' | 'GET' | 'PUT' | 'DELETE', urlSuffix: string, body: any = {}) => {
    setIsLoading(true);
    if (isExpired()) {
      return;
    }

    const request = {
      method: method,
      headers: authHeader(),
    };

    //@ts-ignore
    if (method !== 'GET') request.body = JSON.stringify(body);

    try {
      const response = await fetch(`${process.env.REACT_APP_AWS_ENDPOINT}${urlSuffix}` as string, {
        ...request,
      });

      return response;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = () => {
    reset();
    if (wallet.status === 'disconnected') return;

    Modal.warn({
      title: 'Session expired',
      icon: <ExclamationCircleOutlined />,
      content: 'Your session has expired please sign your token again.',
      onOk() {
        return;
      },
    });
  };

  return { signToken, getUserInfo, fetchDashboard, jwtToken, tokenInfo, isLoading, isExpired, reset };
}

export const account = atom({
  key: 'accounts.user',
  default: null,
});

export const AccountsLoginSelector = selectorFamily<User | null, { token: string | null }>({
  key: 'accounts.login',
  get: ({ token }) => async () => {
    if (token) {
      const response = await fetch(`${process.env.REACT_APP_AWS_ENDPOINT}/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data.data;
      }
    }

    return null;
  },
});
