import { gql } from 'apollo-boost';

export const FETCH_ORGANIZATION_AUCTIONS = gql`
  query fetchOranizationAuctions($seats: [ID]) @api(name: "ethereum") {
    auctions(first: 1000, where: { seller_in: $seats }, orderBy: dateStarted, orderDirection: desc) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      reservePriceInETH
      version
      revenue
      highestBid {
        bidder {
          id
        }
        amount
      }
      seller {
        id
      }
      nft {
        id
        name
        description
        creative
      }
    }
  }
`;
