import { gql } from 'apollo-boost';

export const FETCH_ORGANIZATION_COLLECTIONS = gql`
  query GetOrganizationCollections($seats: [ID!]) @api(name: "ethereum") {
    accounts(where: { id_in: $seats }) {
      collections {
        id
        name
        symbol
        address
        revenue
      }
    }
  }
`;
