import React from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

export default function Environment({ form }: any) {
  return (
    <Item
      label="Environment"
      name="environment"
      extra="Environment this api key should have access to"
      required
      initialValue="master"
      normalize={(s) => s.trim()}>
      <Input style={{ width: '100%' }} />
    </Item>
  );
}
