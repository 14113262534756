import React from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { Collapse, Form } from 'antd';

import AccessToken from './AccessToken';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './ContentfulForm.module.css';
import Environment from './Environment';
import ManagmentAccesToken from './ManagmentAccesToken';
import SpaceId from './Space';
// import ImageTransformationForm from './imageTransformation/ImageTransformationForm';
import { ContentfulValidationFormProps } from './types/ContentfulSectionTypes';

const { Panel } = Collapse;

export default function ContentfulValidationForm({
  form,
  initialValues,
  onFinish,
  showErrorBanner,
  imageTransformations,
  addTransformation,
  removeTransformation,
}: ContentfulValidationFormProps) {
  const [internalForm] = Form.useForm(form);

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel key="1" header="Contentful section">
        <Form
          size="large"
          layout="vertical"
          id="organizationForm"
          name="organizationForm"
          form={internalForm}
          initialValues={initialValues}
          onFinish={onFinish}>
          <SpaceId />
          <Environment />
          <AccessToken />
          <ManagmentAccesToken />
          {/* <ImageTransformationForm
            addTransformation={addTransformation}
            removeTransformation={removeTransformation}
            imageTransformations={imageTransformations}
          /> */}
        </Form>
        {showErrorBanner ? (
          <div className={styles.contenfulValidationErrorMessage}>
            <WarningOutlined />
            <span>The provided information for contentful is not valid, please check and try again...</span>
          </div>
        ) : null}
      </Panel>
    </Collapse>
  );
}
