import { Input, Form, Checkbox, FormInstance } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

import { AvatarInput } from './seatForm/AvatarInput';

const { Item } = Form;

const walletRules = [
  {
    required: true,
    async validator(field: any, address: string) {
      addressCheck.toChecksumAddress(address);
    },
  },
];

interface SeatFormProps {
  form?: FormInstance;
  initialValues?: any;
  onFinish?: (values: any) => void;
}

export function SeatForm({ form, onFinish, initialValues }: SeatFormProps) {
  const [internalForm] = Form.useForm(form);

  return (
    <Form
      form={internalForm}
      id="seatForm"
      name="seatForm"
      size="large"
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}>
      <Item
        label="Full Name"
        name="seatName"
        initialValue={initialValues?.name}
        rules={[{ required: true, message: 'Seat Name is required' }]}>
        <Input />
      </Item>
      <Item label="Seat Wallet" name="seatWallet" initialValue={initialValues?.address} rules={walletRules}>
        <Input />
      </Item>
      <Item valuePropName="checked" name="isAdmin" initialValue={initialValues?.permissions?.admin}>
        <Checkbox>Administrator</Checkbox>
      </Item>
      <Item valuePropName="checked" name="auctionsPermission" initialValue={initialValues?.permissions?.auctions}>
        <Checkbox>Auctions</Checkbox>
      </Item>
      <Item valuePropName="checked" name="mintPermission" initialValue={initialValues?.permissions?.mint}>
        <Checkbox>Collections</Checkbox>
      </Item>
      <Item label="Avatar" name="avatar" validateTrigger="onBlur">
        <AvatarInput />
      </Item>
    </Form>
  );
}
