import React, { useCallback, useEffect, useMemo } from 'react';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  DollarCircleOutlined,
  BankOutlined,
  HighlightOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Drawer } from 'antd';
import cx from 'classnames';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import Logo from 'assets/metadyn.png';
import LogoShort from 'assets/monegraph-letter.png';
import { routes } from 'config/routes';
import usePermissions from 'pages/admin/hooks/use-permissions';
import { useDashboardApi } from 'pages/admin/hooks/useDashboardApi';

import MenuItem from './MenuItem';
import styles from './Sidebar.module.css';
import { SidebarProps } from './Sidebar.types';
import SubMenuItem from './SubMenuItem';

const { Sider } = Layout;

export default function Sidebar({ isMobile, collapsed, onCollapse }: SidebarProps) {
  const wallet = useWallet();
  const location = useLocation();
  const { permissions, account } = usePermissions();
  const match = useRouteMatch<any>();
  const dashboard = useDashboardApi();
  useEffect(() => {
    if (isMobile === true) {
      onCollapse(true);
    }
  }, [isMobile, onCollapse]);

  const openKeys: Array<string> = useMemo(() => {
    const currentRoute = Object.values<any>(routes).find((route: any) => route.path === match.path);

    return [currentRoute.path, currentRoute.submenu];
  }, [match.params.contract, match.path]);

  const onDisconnect = useCallback(async () => {
    dashboard.reset();
    wallet.reset();
  }, [wallet, dashboard]);

  const sidebarContent = (
    <Sider collapsible collapsed={!isMobile && collapsed} onCollapse={onCollapse} trigger={null}>
      <div className={styles.container}>
        <div className={cx([styles.logoContainer, 'p-2'])}>
          <img className={styles.logo} src={!isMobile && collapsed ? LogoShort : Logo} alt="Metadyn" />
        </div>
        <div className={styles.menuContainer}>
          <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} defaultOpenKeys={openKeys}>
            <SubMenuItem
              title="Metadyn Admin"
              icon={<AppstoreOutlined />}
              key="administrator"
              permission={permissions?.monegraph?.admin}>
              <MenuItem label="Organizations" path="/organizations" key="/organizations" />
              <MenuItem label="Users" path="/users" key="/users" />
              <MenuItem label="Auctions" path="/all-auctions/" key="/all-auctions/" />
              <MenuItem label="Collections" path="/all-collections/" key="/all-collections/" />
            </SubMenuItem>

            <SubMenuItem
              title="Organization"
              key="organization"
              icon={<AppstoreOutlined />}
              permission={permissions?.organization?.admin}>
              <MenuItem
                label="Manage Seats"
                path={`/organizations/${account?.organization}/manage-seats`}
                key={`/organizations/${account?.organization}/manage-seats`}
                icon={<BankOutlined />}
              />
              <MenuItem
                label="Collections"
                path={`/organizations/${account?.organization}/collections`}
                key={`/organizations/${account?.organization}/collections`}
                icon={<AppstoreOutlined />}
              />
              <MenuItem
                label="Auctions"
                path={`/organizations/${account?.organization}/auctions`}
                key={`/organizations/${account?.organization}/auctions`}
                icon={<DollarCircleOutlined />}
              />
            </SubMenuItem>

            <MenuItem
              icon={<HighlightOutlined />}
              label="Gallery"
              path="/wizard"
              key="/wizard"
              permission={permissions?.auctions?.creator || permissions?.minting?.creator}
            />

            <MenuItem
              icon={<DollarCircleOutlined />}
              label="Auctions"
              path="/auction"
              key="/auction"
              permission={permissions?.auctions?.creator}
            />

            <MenuItem
              icon={<AppstoreOutlined />}
              label="Collections"
              path={`/collections/`}
              key={`/collections/`}
              permission={permissions?.minting?.creator}
            />
          </Menu>
        </div>
        <div>
          <Menu theme="dark" mode="inline" openKeys={[]} selectedKeys={[]}>
            <Menu.Item icon={<LogoutOutlined />} title={false} key="logout" onClick={onDisconnect}>
              Disconnect
            </Menu.Item>
            <Menu.Item title={false} key="collapsed" onClick={() => onCollapse(!collapsed)}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </Sider>
  );

  return isMobile ? (
    <Drawer
      placement="left"
      closable={false}
      width={200}
      style={{ height: '100vh', padding: 0 }}
      bodyStyle={{ height: '100vh', padding: 0, display: 'flex', flexDirection: 'row' }}
      visible={!collapsed}
      onClose={() => onCollapse(true)}>
      {sidebarContent}
    </Drawer>
  ) : (
    sidebarContent
  );
}
