import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import styles from './LoadingModal.module.css';

interface ILoadingModal {
  visible: boolean;
}

const LoadingModal = ({ visible }: ILoadingModal) => {
  return (
    <Modal
      visible={visible}
      centered
      footer={false}
      bodyStyle={{
        padding: '10px',
        borderRadius: '50%',
      }}
      style={{ background: 'transperent', maxWidth: '70px', padding: 0, borderRadius: '50%' }}
      closable={false}>
      <LoadingOutlined className={styles.loadingModal} />
    </Modal>
  );
};

export default LoadingModal;
