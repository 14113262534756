import { Dispatch, SetStateAction } from 'react';

import { OrganizationType } from 'pages/admin/types/DashboardTypes';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';

export interface ColumnsDefinitionsProps {
  setOrganizations: Dispatch<SetStateAction<OrganizationType[] | null>>;
  organizations: OrganizationType[] | null;
}

export const ColumnsDefinitions = ({ setOrganizations, organizations }: ColumnsDefinitionsProps) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Seats Used',
      dataIndex: 'accounts',
      key: 'accounts',
    },
    {
      title: 'Seats Available',
      dataIndex: 'seats',
      key: 'seats',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <>
          <EditButton record={record} />{' '}
          <DeleteButton record={record} setOrganizations={setOrganizations} organizations={organizations} />{' '}
        </>
      ),
    },
  ];
};
