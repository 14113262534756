// @ts-ignore
import pinataSDK from '@pinata/sdk';
// @ts-ignore
import Client from 'ipfs-http-client';

import env from 'lib/env';
import { PinataMetadata, PinataOptions } from 'lib/ipfs.types';

export * from 'lib/ipfs.types';

const pinata = pinataSDK(env.PINATA_KEY, env.PINATA_SECRET);
const client = new Client(env.IPFS_URL as string);

export async function pinJSONToIPFS(json: Record<string, unknown>, options: PinataOptions = {}) {
  const { path } = await client.add(Buffer.from(JSON.stringify(json)), {
    pin: true,
  });

  await pinata.pinByHash(path, options);

  return path;
}

function sleep(seconds: number) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, seconds * 1000);
  });
}

export async function checkJobs() {
  const headers = new Headers();

  headers.set('pinata_api_key', env.PINATA_KEY ?? '');
  headers.set('pinata_secret_api_key', env.PINATA_SECRET ?? '');

  const response = await fetch('https://api.pinata.cloud/pinning/pinJobs?limit=300', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  });

  const result = await response.json();

  if (result.total > 200) {
    await sleep(5);
    await checkJobs();
  }
}

export async function pinFileToIpfs(
  file: File,
  { metadata, wrapWithDirectory = true }: { metadata?: PinataMetadata; wrapWithDirectory?: boolean }
) {
  const data = new FormData();
  const headers = new Headers();

  data.append('file', file);

  if (metadata) {
    data.append('pinataMetadata', JSON.stringify(metadata));
  }

  data.append(
    'pinataOptions',
    JSON.stringify({
      wrapWithDirectory,
    })
  );

  headers.set('pinata_api_key', env.PINATA_KEY ?? '');
  headers.set('pinata_secret_api_key', env.PINATA_SECRET ?? '');

  const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
    method: 'POST',
    mode: 'cors',
    body: data,
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(`IPFS Pinning service is down`);
  }

  const result = await response.json();

  if (result.error || !result.IpfsHash) {
    throw new Error(`Failed to upload to pinata: ${result.error}`);
  }

  return result;
}
