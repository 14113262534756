/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';

import { get } from 'lodash';
import { useRecoilValueLoadable } from 'recoil';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useWallet } from 'use-wallet';

import { useDashboardApi, AccountsLoginSelector } from './useDashboardApi';

export const permissionsAtom = atom<{ loading: Boolean; permissions: any; account: any }>({
  key: 'permissions',
  default: {
    loading: false,
    account: null,
    permissions: null,
  },
});

export default function usePermissions() {
  const wallet = useWallet();
  const dashboard = useDashboardApi();
  const { contents: account } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));

  const [permissions, setPermissions] = useRecoilState(permissionsAtom);

  useMemo(async () => {
    if (!permissions.permissions || !wallet.account) {
      setPermissions({
        loading: !!wallet.account,
        account: null,
        permissions: null,
      });
    }

    if (wallet.account && account) {
      const permissions = account?.user?.permissions;
      setPermissions({
        loading: false,
        account: account?.user,
        permissions: {
          monegraph: {
            minter: true,
            admin: get(permissions, 'platformAdmin', false),
          },
          minting: {
            creator: get(permissions, 'mint', false),
          },
          auctions: {
            creator: get(permissions, 'auctions', false),
          },
          organization: {
            admin: get(permissions, 'admin', false),
          },
        },
      });
    }
  }, [account, wallet.account]);

  return permissions;
}
