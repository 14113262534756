import { gql } from 'apollo-boost';

import ajv from 'lib/ajv';

export const FETCH_TOKENS_METADATA_KEY = 'collections.tokens.GetTokenMetadata';

ajv.compile({
  $id: FETCH_TOKENS_METADATA_KEY,
  $schema: 'http://json-schema.org/draft-07/schema#',
  required: ['collectionId', 'tokenId', 'accountId'],
  properties: {
    accountId: {
      type: 'string',
      minLength: 1,
    },

    collectionId: {
      type: 'string',
      minLength: 1,
    },

    tokenId: {
      type: 'string',
      minLength: 1,
    },
  },
  type: 'object',
});

export const FETCH_COLLECTION_TREE = gql`
  query GetCollectionTree($accountId: ID!) @api(name: "ethereum") {
    nftholders(first: 1000, where: { account: $accountId }) {
      nft {
        id
        tokenId
        name
        collection {
          id
          name
          address
        }
      }
    }
  }
`;

export const FETCH_TOKENS_METADATA = gql`
  query GetTokenMetadata($accountId: ID!, $collectionId: ID!, $tokenId: BigInt!) @api(name: "ethereum") {
    collection(id: $collectionId) {
      id
      type
      tokens(where: { tokenId: $tokenId }) {
        id
        name
        artist
        description
        creative
        tokenId
        quantity
        holders(where: { account: $accountId }) {
          id
          quantity
        }
      }
    }
  }
`;
