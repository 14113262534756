import { gql } from 'apollo-boost';

export const FETCH_AUCTIONS_BY_SELLER = gql`
  query GetAuctionsBySeller($wallets: [String], $organization: String) @api(name: "ethereum") {
    organizationContract(id: $organization) {
      id
      auctions
    }

    auctions(first: 1000, where: { seller_in: $wallets }, orderBy: dateStarted, orderDirection: desc) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      reservePriceInETH
      version
      quantity
      revenue
      highestBid {
        bidder {
          id
        }
        amount
      }
      bids(orderBy: datePlaced, orderDirection: desc) {
        amount
        bidder {
          id
        }
        datePlaced
      }
      seller {
        id
      }
      nft {
        id
        name
        description
        creative
      }
    }

    _meta {
      block {
        hash
        number
      }
    }
  }
`;
