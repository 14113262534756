import React from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

export default function SpaceId() {
  return (
    <Item label="Space id" normalize={(s) => s.trim()} name="spaceId" extra="Optional Field to validate on contenful">
      <Input style={{ width: '100%' }} />
    </Item>
  );
}
