import { useDashboardApi } from './useDashboardApi';

export interface Draft {
  payload: string;
  organization: string;
  createdAt: number;
  id: string;
  name: string;
  createdBy: string;
}

export type Result = Record<
  string,
  {
    name: string;
    drafts: Draft[];
  }
> & {
  me: Draft[];
};

export default function useDrafts() {
  const Dashboard = useDashboardApi();
  const suffixUrl = '/auction/draft';

  const getAll = async (): Promise<Result | null> => {
    const response = await Dashboard.fetchDashboard('GET', suffixUrl);

    if (response?.ok) {
      const result = await response?.json();
      return result?.data as Result;
    }

    return null;
  };

  const getMyDrafts = async () => {
    const response = await getAll();
    if (response) {
      return response.me;
    }
    return null;
  };

  const getByUser = async (userId: string) => {
    const response = await getAll();
    if (response) {
      return response[userId];
    }
    return null;
  };

  const getByIdOrName = async (search: string) => {
    const response = await getAll();
    if (response) {
      const allDrafts = Object.values(response)
        .map((value) => {
          if (Array.isArray(value)) return value;
          return value.drafts;
        })
        .flat();

      let result: Draft | undefined = undefined;

      result = allDrafts.find((draft) => draft?.id === search);

      if (!result) result = allDrafts.find((draft) => draft?.name === search);

      return result ?? null;
    }

    return null;
  };

  const createDraft = async (name: string, payload: any) => {
    await Dashboard.fetchDashboard('POST', `${suffixUrl}`, {
      name: name,
      payload: JSON.stringify(payload),
    });
  };

  const deleteDraft = async (id: string) => {
    await Dashboard.fetchDashboard('DELETE', `${suffixUrl}/${id}`, {});
  };

  return { getAll, getMyDrafts, getByUser, getByIdOrName, deleteDraft, createDraft };
}
