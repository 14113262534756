import React from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

export default function ManagmentAccessToken() {
  return (
    <Item
      label="Management Access Token"
      name="managementAccessToken"
      extra="Managment access token copied from contentful"
      required
      normalize={(s) => s.trim()}>
      <Input style={{ width: '100%' }} />
    </Item>
  );
}
