/* eslint-disable react/no-multi-comp */
import { formatEther } from '@ethersproject/units';
import { TableColumnType } from 'antd';
import { Link } from 'react-router-dom';

import { CollectionsActions } from './CollectionActions';

const columns: Array<TableColumnType<any>> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name: string, collection) => <Link to={`${collection.address}/`}>{name}</Link>,
  },
  { title: 'Symbol', dataIndex: 'symbol' },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Revenue ETH',
    dataIndex: 'revenue',
    key: 'revenue',
    sorter: {
      compare(a, b) {
        return a.revenue - b.revenue;
      },
      multiple: 1,
    },
    render: (value: number) => {
      return `${parseFloat(formatEther(value)).toFixed(4)}`;
    },
  },
  {
    title: 'Actions',
    dataIndex: 'address',
    render: (address: string) => <CollectionsActions address={address} />,
  },
];

export default columns;
