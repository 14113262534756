import { useCallback, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import useLocationQuery from 'hooks/use-location-query';
import useDrafts from 'pages/admin/hooks/useDrafts';

export default function SaveProgress({ form }: any) {
  const locationQuery = useLocationQuery();
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const draftApi = useDrafts();
  const draft = locationQuery.get('draft');

  const [modalForm] = Form.useForm();

  const saveProgress = useCallback(async () => {
    const name = draft || modalForm.getFieldValue('name');
    if (name) {
      const exist = await draftApi.getByIdOrName(name);
      if (exist) {
        modalForm.setFields([{ name: 'name', value: '', errors: [`Draft name already exists`] }]);
      } else {
        const values = form.getFieldsValue();
        setVisible(false);
        await draftApi.createDraft(name, values);
        const response = await draftApi.getMyDrafts();
        if (response) {
          const last = response.shift();
          history.replace(history.location.pathname + `?draft=${last?.id}`);
        }
      }
    }
  }, [form, modalForm]);

  const checkSave = useCallback(() => {
    if (draft) {
      Modal.confirm({
        title: 'Delete Draft?',
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure you want to delete this draft"?`,
        okText: 'Delete',
        cancelText: 'Cancel',
        async onOk() {
          await draftApi.deleteDraft(draft);
          history.replace(history.location.pathname);
        },
      });
    } else {
      setVisible(true);
    }
  }, [draft]);

  return (
    <>
      <Form.Item label="">
        <Button type="primary" className="w-100 btn-secondary" danger={!!draft} onClick={checkSave}>
          {draft ? 'Delete Draft' : 'Save Progress'}
        </Button>
      </Form.Item>

      <Modal visible={visible} title="Save progress?" onCancel={() => setVisible(false)} onOk={saveProgress}>
        <Form form={modalForm}>
          <Form.Item label="Draft Name" name="name" extra="The name of this draft">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
