/* eslint-disable no-console */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import { get, mapValues, transform } from 'lodash';

import env from 'lib/env';
import CompiledRoute from 'lib/router/Route';
import withAuth from 'lib/router/withAuth';
import AllAuctions from 'pages/admin/allAuctions/AllAuctions';
import AllCollections from 'pages/admin/allCollections/AllCollections';
import AuctionWizard from 'pages/admin/auctionWizard/AuctionWizard';
import Auctions from 'pages/admin/auctions/Auctions';
import Collections from 'pages/admin/collections/Collections';
import AuctionCreate from 'pages/admin/create/Create';
import CreateCollection from 'pages/admin/createCollection/CreateCollection';
import EditPublicMint from 'pages/admin/editPublicMint/EditPublicMint';
import AdminHome from 'pages/admin/home/Home';
import Login from 'pages/admin/login/Login';
import Mint from 'pages/admin/mint/Mint';
import Minters from 'pages/admin/minters/Minters';
import OrganizationAuctions from 'pages/admin/organizations/auctions';
import OrganizationCollections from 'pages/admin/organizations/collections';
import Organization from 'pages/admin/organizations/create/Organization';
import Organizations from 'pages/admin/organizations/list/Organizations';
import ManageSeats from 'pages/admin/organizations/manageSeats/ManageSeats';
import MyOrganization from 'pages/admin/organizations/myOrganization/MyOrganization';
import OrganizationWizard from 'pages/admin/organizations/organizationWizard/OrganizationWizard';
import PublicMint from 'pages/admin/publicMint/PublicMint';
import TokensAdmin from 'pages/admin/tokens/Tokens';
import Users from 'pages/admin/users/Users';
import { AuctionPage } from 'pages/auctions/auction/Auction';
import Gallery from 'pages/auctions/gallery/Gallery';
import Home from 'pages/auctions/home/Home';
import Wallet from 'pages/wallet/Wallet';

const location = window.location;

const siteRoutes = {
  auctions: {
    home: {
      path: '/',
      component: Home,
    },

    auction: {
      path: ['/auctions/:contract', '/auctions/:contract/:auctionId'],
      component: AuctionPage,
    },
    artists: {
      path: '/gallery/:id',
      component: Gallery,
    },
    wallet: {
      path: '/wallet',
      component: Wallet,
    },
  },

  admin: {
    home: {
      path: '/',
      component: withAuth(AdminHome),
    },
    login: {
      path: '/login',
      component: Login,
    },
    organizations: {
      path: '/organizations',
      component: withAuth(Organizations),
      submenu: 'administrator',
    },
    organizationsCreate: {
      path: '/organizations/create',
      component: withAuth(Organization),
    },
    organizationsUpdate: {
      path: '/organization/:id',
      component: withAuth(Organization),
    },
    organizationsManageSeats: {
      path: '/organizations/:id/manage-seats',
      component: withAuth(ManageSeats),
      submenu: 'organization',
    },

    organizationsCollections: {
      path: '/organizations/:id/collections',
      component: withAuth(OrganizationCollections),
      submenu: 'organization',
    },
    organizationsAuctions: {
      path: '/organizations/:id/auctions',
      component: withAuth(OrganizationAuctions),
      submenu: 'organization',
    },

    myOrganization: {
      path: '/my-organization',
      component: withAuth(MyOrganization),
    },
    auctions: {
      path: '/auction',
      component: withAuth(Auctions),
      submenu: 'auctions',
    },
    create: {
      path: '/auction/create/:contract',
      component: withAuth(AuctionCreate),
      submenu: 'auctions',
    },
    auctionWizard: {
      path: '/auction/:id*/wizard',
      component: withAuth(AuctionWizard),
      submenu: 'auctions',
    },
    wizard: {
      path: '/wizard',
      component: withAuth(OrganizationWizard),
    },
    collections: {
      path: '/collections',
      component: withAuth(Collections),
    },
    minters: {
      path: '/collections/:contract/minter',
      component: withAuth(Minters),
    },
    minting: {
      path: '/collections/:contract/mint',
      component: withAuth(Mint),
    },
    publicMint: {
      path: '/collections/:contract/public-mint',
      component: withAuth(PublicMint),
    },
    editPublicMint: {
      path: '/collections/:contract/public-mint/:publicMint',
      component: withAuth(EditPublicMint),
    },
    createCollection: {
      path: '/collections/create',
      component: withAuth(CreateCollection),
    },
    tokens: {
      path: '/collections/:contract/',
      component: withAuth(TokensAdmin),
    },
    collectionTab: {
      path: '/collections/:contract/:tab',
      component: withAuth(TokensAdmin),
    },
    users: {
      path: '/users',
      component: withAuth(Users),
      submenu: 'administrator',
    },
    allAuctions: {
      path: '/all-auctions',
      component: withAuth(AllAuctions),
      submenu: 'administrator',
    },
    allCollection: {
      path: '/all-collections',
      component: withAuth(AllCollections),
      submenu: 'administrator',
    },
  },
};

const getRoutes = function (subdomain: string) {
  let sub = env.SUBDOMAIN || '';
  let routes = get(siteRoutes, sub, null);
  if (!routes) {
    switch (subdomain) {
      case 'admin':
        routes = siteRoutes.admin;
        break;

      default:
        routes = siteRoutes.auctions;
    }
  }

  return routes;
};

export const routes = getRoutes(location.host.split('.')[0]);

export const paths = mapValues(routes, (route) => {
  return route.path;
});

export default function getCompiledRoutes() {
  const reactRoutes = transform<typeof routes, JSX.Element[]>(
    routes,
    (routes, route, key: string) => {
      routes.push(<CompiledRoute {...route} exact={route.exact === false ? false : true} key={key} />);

      return routes;
    },
    []
  );

  return reactRoutes;
}
