import { get, isString } from 'lodash';
import { atom, useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import useBlockTimestamp from 'hooks/use-block-timestamp';
import useMonitorQuery from 'hooks/use-monitor-query';
import { priceInEthereum } from 'pages/auctions/home/state';
import { transformAuction } from 'transforms/auction';

import { FETCH_AUCTIONS_BY_SELLER } from './Auctions.queries';

export const transferState = atom<Record<string, any>>({
  key: 'auctions.token.transfer',
  default: {
    visible: false,
    transacting: false,
    auction: null,
    progress: [],
  },
});

export function useAuctions(organization: string): any {
  const wallet = useWallet();
  const { contents: ethereumValue } = useRecoilValueLoadable<any>(priceInEthereum);
  const getBlockTimestamp = useBlockTimestamp();

  return useMonitorQuery(
    {
      query: FETCH_AUCTIONS_BY_SELLER,
      variables: {
        wallets: [wallet.account?.toLowerCase()],
        organization,
      },
      defaultValidator(context: any) {
        return isString(organization) && isString(wallet.account) && ethereumValue > 0;
      },
      async transform({ data }) {
        const blockTimestamp = await getBlockTimestamp(data._meta.block.number);
        return {
          contract: get(data.organizationContract, 'auctions', false),
          auctions: data.auctions.map((auction: any) => transformAuction(auction, { ethereumValue, blockTimestamp })),
        };
      },
    },
    [wallet.account, organization, ethereumValue]
  );
}
