import { useDashboardApi } from 'pages/admin/hooks/useDashboardApi';
import { OrganizationType } from 'pages/admin/types/DashboardTypes';

export function useOrganization() {
  const dashboard = useDashboardApi();

  const getAll = async () => {
    const response = await dashboard.fetchDashboard('GET', '/organizations');
    let result: OrganizationType[] = [];
    if (response?.ok) {
      result = (await response.json()).data;
    }
    return result;
  };

  const getById = async (organizationId: string) => {
    const response = await getAll();
    if (response.length > 0) {
      const result = response.find((item) => item.id === organizationId);
      return result;
    }
    return null;
  };
  const createNew = async (organization: any) => {
    await dashboard.fetchDashboard('POST', '/organizations', {
      ...organization,
    });
  };
  const editExisting = async (organizationId: string, organization: any) => {
    await dashboard.fetchDashboard('PUT', `/organizations/${organizationId}`, {
      ...organization,
    });
  };

  return { getAll, getById, editExisting, createNew };
}
