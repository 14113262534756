import { gql } from 'apollo-boost';

export const FETCH_ACCOUNTS = gql`
  query GetAccounts @api(name: "polygon") {
    accounts {
      id
      organization {
        id
        name
        seats
      }
    }
  }
`;

export const FETCH_AUCTIONS = gql`
  query GetAuctionsBySeller @api(name: "ethereum") {
    auctions(first: 1000, orderBy: dateStarted, orderDirection: desc) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      reservePriceInETH
      version
      revenue
      highestBid {
        bidder {
          id
        }
        amount
      }
      seller {
        id
      }
      nft {
        id
        name
        description
        creative
      }
    }
  }
`;
