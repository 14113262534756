import React, { useCallback, useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row, Skeleton, Spin, Typography } from 'antd';
import { createClient } from 'contentful';
import { get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import Layout from 'layout/AdminLayout';
import { useOrganization } from 'pages/admin/hooks/useOrganization';
import { OrganizationType } from 'pages/admin/types/DashboardTypes';

import OrganizationForm from '../components/OrganizationForm';
import ContentfulValidationForm from '../components/contentfulForm/ContenfulValidationForm';
import { ImageTransformation, ImageTransformations } from '../components/contentfulForm/types/ContentfulSectionTypes';

const { useForm } = Form;

export default function Organization() {
  const [showErrorBanner, setError] = useState(false);
  const [form] = useForm();
  const [disabled, setDisabled] = useState(false);
  const params = useParams<any>();
  const wallet = useWallet();
  const history = useHistory();

  const goBack = useCallback(() => {
    history.push('/organizations');
  }, [history]);
  const organizationId = get(params, 'id');

  const { getById, createNew, editExisting } = useOrganization();

  const [contents, setContents] = useState<OrganizationType>();
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);

  const imageTransformations: ImageTransformations = [];
  const [imageTransformation, setTransformation] = useState(imageTransformations);

  useEffect(() => {
    const request = async () => {
      try {
        setState(true);
        const result = await getById(organizationId);
        if (result) {
          const initialValues = {
            ...result,
            feesPublicMinting: result?.fees?.publicMinting,
            auction: result?.fees?.auction,
          };
          setContents(initialValues);
        }
      } catch (ex) {
      } finally {
        setState(false);
      }
    };
    if (organizationId) {
      request();
    }
  }, [organizationId]);

  const addTransformation = useCallback((transformation: ImageTransformation) => {
    imageTransformation.push(transformation);
    setTransformation(imageTransformation);
  }, []);

  const removeTransformation = useCallback((key: String) => {
    const index = imageTransformation.findIndex((item) => item.key === key);
    const result = imageTransformation.filter((elem) => elem.key !== key);
    imageTransformation.splice(index, 1);
    setTransformation(result);
  }, []);

  const onFinish = useCallback(
    async (values: any) => {
      setLoading(true);
      let organization = {};
      const spaceId = values.spaceId;
      const Environment = values.environment;
      const accessToken = values.accessToken;
      const managmentAccessToken = values.managmentAccessToken;
      if (spaceId || accessToken || managmentAccessToken) {
        const client = createClient({
          space: spaceId ?? '',
          environment: Environment ?? '',
          accessToken: accessToken ?? '',
          host: 'preview.contentful.com',
        });
        console.warn(client);
        try {
          await client.getSpace();
          setError(false);
          //@ts-ignore
          organization.contentful = {
            accessToken: values.accessToken,
            environment: values.environment,
            managementAccessToken: values.managementAccessToken,
            spaceId: values.spaceId,
          };
        } catch (e) {
          setError(true);
          setLoading(false);
          return;
        }
      }

      try {
        organization = {
          name: values.name,
          seats: Number.parseInt(values.seats), // > 0
          admins: [
            {
              name: values.adminName,
              address: values.adminWallet,
            },
          ],
          fees: {
            auction: values.auction, // 1 to 100
            publicMinting: values.feesPublicMinting, // 1 to 100
          },
          ...organization,
        };
        if (!organizationId) {
          await createNew(organization);
        } else {
          //@ts-ignore
          delete organization.admins;
          await editExisting(organizationId, organization);
        }

        form.resetFields();

        setLoading(false);

        goBack();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [organizationId, form, goBack, wallet.account]
  );

  const onChangeFields = useCallback(() => {
    setDisabled(form.getFieldsError().some((item) => item.errors.length > 0));
  }, [form]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Typography.Title>{contents?.name || 'New Organization'}</Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center" className="p-2">
              <Col xs={24} md={18} lg={12}>
                <Spin spinning={loading}>
                  {state ? (
                    <>
                      <Skeleton active paragraph={false} />
                      <Skeleton active paragraph={false} />
                    </>
                  ) : (
                    <>
                      <OrganizationForm organizationId={organizationId} initialValues={contents} form={form} />
                      <ContentfulValidationForm
                        initialValues={contents?.contentful}
                        form={form}
                        showErrorBanner={showErrorBanner}
                        imageTransformations={imageTransformation}
                        addTransformation={addTransformation}
                        removeTransformation={removeTransformation}
                      />
                    </>
                  )}
                  <Form form={form} onFieldsChange={onChangeFields} onFinish={onFinish}>
                    <Form.Item>
                      <Button type="primary" className="w-100 btn-primary mt-3" htmlType="submit" disabled={disabled}>
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
