import React, { useCallback, useState } from 'react';

import { Card, Row, Col, Typography, Table, Spin, Button } from 'antd';
import { each } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import useRecoilEffect from 'hooks/use-recoil-effect';
import Layout from 'layout/AdminLayout';
import apollo from 'lib/apollo/client';
import { AccountsLoginSelector, useDashboardApi } from 'pages/admin/hooks/useDashboardApi';

import columnsDefinition from './components/ColumnDefinition';
import { FETCH_ORGANIZATION_COLLECTIONS } from './queries';

export default function OrganizationCollections() {
  const [list, setList] = useState<any>(null);
  const wallet = useWallet();
  const history = useHistory();
  const dashboard = useDashboardApi();
  const { contents: account } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));

  const seats = account?.organization?.users?.map((user: any) => user?.address?.toLowerCase());

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FETCH_ORGANIZATION_COLLECTIONS,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
          variables: {
            seats,
          },
        })
        .subscribe({
          next: function ({ data }: any) {
            let list: any[] = [];

            if (data) {
              each(data?.accounts, (account) => {
                list = list.concat(account.collections);
              });
            }

            setList(list);
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [setList, wallet.account]
  );

  const createCollection = useCallback(() => {
    history.push('/collections/create');
  }, [history]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Collections</Typography.Title>
              </Col>
              <Col>
                <Button type="primary" className=" btn-primary" onClick={createCollection}>
                  New Collection
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Spin spinning={!list}>
                  <Table columns={columnsDefinition} dataSource={list} scroll={{ x: true }} />
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
