import { gql } from 'apollo-boost';

export const FETCH_USER_COLLECTIONS = gql`
  query GetUserCollections($seats: ID) @api(name: "ethereum") {
    account(id: $seats) {
      collections {
        id
        name
        symbol
        address
        revenue
      }
    }
  }
`;
