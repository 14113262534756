import React from 'react';

import { Form, InputNumber } from 'antd';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'You must enter a number between 1 and 100',
  },
];

export default function OrganizationFees() {
  return (
    <>
      <Item label="Organization Fees">
        <Item
          label="Auction"
          name="auction"
          rules={rules}
          required
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
          <InputNumber style={{ width: '100%' }} type="number" min={1} max={100} />
        </Item>
        <Item
          label="Public Minting"
          name="feesPublicMinting"
          required
          rules={rules}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
          <InputNumber style={{ width: '100%' }} type="number" min={1} max={100} />
        </Item>
      </Item>
    </>
  );
}
