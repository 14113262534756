import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row, Card, Typography, Button, Spin, Table } from 'antd';
import { useHistory } from 'react-router-dom';

import Layout from 'layout/AdminLayout';
import { useOrganization } from 'pages/admin/hooks/useOrganization';
import { OrganizationType } from 'pages/admin/types/DashboardTypes';

import { ColumnsDefinitions } from './components/ColumnsDefinition';

export default function Organizations(): JSX.Element | null {
  const history = useHistory();
  const [organizations, setOrganizations] = useState<OrganizationType[] | null>(null);
  const { getAll } = useOrganization();
  const arg = {
    setOrganizations,
    organizations,
  };
  const columns = ColumnsDefinitions(arg);

  const createOrganization = useCallback(() => {
    history.push('/organizations/create');
  }, [history]);

  const request = async () => {
    const list = await getAll();
    setOrganizations(list);
  };

  useEffect(() => {
    request();
  }, []);

  return !true ? null : (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Organizations</Typography.Title>
              </Col>
              <Col>
                <Button type="primary" className=" btn-primary" onClick={createOrganization}>
                  New Organizations
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Spin spinning={organizations === null}>
              <Table dataSource={organizations || []} columns={columns} scroll={{ x: true }} />
            </Spin>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
