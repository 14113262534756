import { useState } from 'react';

import { Row, Col, Typography, Card, Table } from 'antd';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import useRecoilEffect from 'hooks/use-recoil-effect';
import Layout from 'layout/AdminLayout';
import apollo from 'lib/apollo/client';
import { AccountsLoginSelector, useDashboardApi } from 'pages/admin/hooks/useDashboardApi';

import columnDefinition from './components/ColumnDefinitions';
import { FETCH_ORGANIZATION_AUCTIONS } from './queries';

export default function OrganizationAuctions() {
  const wallet = useWallet();

  const [auctions, setAuctions] = useState<any>(null);
  const dashboard = useDashboardApi();
  const { contents: account } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));

  const seats = account?.organization?.users?.map((user: any) => user?.address?.toLowerCase());

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FETCH_ORGANIZATION_AUCTIONS,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
          variables: {
            seats,
          },
        })
        .subscribe({
          next: function ({ data }: any) {
            if (data) {
              setAuctions(data.auctions);
            }
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [setAuctions, wallet.account]
  );

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Auctions</Typography.Title>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                {/* <Spin spinning={auctionsState === 'loading'}> */}
                <Table columns={columnDefinition} dataSource={auctions ? auctions : []} scroll={{ x: true }} />
                {/* </Spin> */}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
