import React, { useCallback, useMemo, useState } from 'react';

import { Button, Card, Col, Form, Input, Checkbox, Row, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import MonegraphERC1155Factory from 'contracts/monegraph-erc1155-factory';
import MonegraphERC721Factory from 'contracts/monegraph-erc721-factory';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import Layout from 'layout/AdminLayout';
import env from 'lib/env';

import { useDashboardApi, AccountsLoginSelector } from '../hooks/useDashboardApi';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'This field is required',
  },
];

export default function CreateCollection() {
  const wallet = useWallet();
  const [form] = useForm();
  const history = useHistory();
  const active = useMemo(() => wallet.status === 'connected', [wallet.status]);
  const [disabled, setDisabled] = useState(active);
  const [creating, setCreating] = useState(false);
  const dashboard = useDashboardApi();
  const { contents: account } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));

  const [state] = useState({
    loading: true,
    monegraph: get(account, 'user.permissions.platformAdmin', false),
    isAdmin: get(account, 'user.permissions.admin', false),
    isCreator: get(account, 'user.permissions.mint', false),
  });
  const executeMethod = useExecuteMethod();

  const erc721Factory = useContract(MonegraphERC721Factory, {
    address: env.MONEGRAPH_ERC721_FACTORY,
  });

  const erc1155Factory = useContract(MonegraphERC1155Factory, {
    address: env.MONEGRAPH_ERC1155_FACTORY,
  });

  const onFinish = useCallback(
    async (values: any) => {
      if (active) {
        setCreating(true);

        const factory = values.editions ? erc1155Factory : erc721Factory;

        try {
          if (state.isCreator) {
            await executeMethod(factory.createContract, [values.name, values.symbol, '0x'], {
              from: wallet.account,
            });

            history.push('/collections/');
          }
        } finally {
          setCreating(false);
        }
      }
    },
    [erc721Factory?.address, erc1155Factory?.address, active, wallet.account, state]
  );

  const onChangeFields = useCallback(() => {
    setDisabled(form.getFieldsError().some((item) => item.errors.length > 0));
  }, [form]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Typography.Title>Deploy Your Collection</Typography.Title>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center" gutter={16}>
              <Col span={16}>
                <Spin spinning={creating} delay={500}>
                  <Form
                    id="mintForm"
                    labelCol={{}}
                    wrapperCol={{ span: 22 }}
                    layout="vertical"
                    onFieldsChange={onChangeFields}
                    onFinish={onFinish}
                    form={form}
                    size="large">
                    <Item label="Collection Name" required name="name" rules={rules}>
                      <Input />
                    </Item>

                    <Item label="Collection Symbol" required name="symbol" rules={rules}>
                      <Input />
                    </Item>

                    <Item label="Support Editions" name="editions" valuePropName="checked">
                      <Checkbox defaultChecked={false} />
                    </Item>

                    <Item label="">
                      <Button type="primary" className="w-100 btn-primary" htmlType="submit" disabled={disabled}>
                        Create Collection
                      </Button>
                    </Item>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
