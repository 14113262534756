import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Row, Spin, Typography, Empty } from 'antd';
import { find, get, parseInt } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import LoadingModal from 'components/LoadingModal/LoadingModal';

import useRecoilEffect from 'hooks/use-recoil-effect';
import Layout from 'layout/AdminLayout';
import apollo from 'lib/apollo/client';
import { PinataMetadata, pinFileToIpfs, pinJSONToIPFS } from 'lib/ipfs';
import { AccountsLoginSelector, useDashboardApi } from 'pages/admin/hooks/useDashboardApi';

import { FETCH_ORGANIZATION } from '../create/Organization.queries';

import Seat from './components/Seat';
import SeatFormModal from './components/SeatFormModal';

const handleFileInput = async (value: string | File, metadata: PinataMetadata) => {
  if (!value) return '';

  if (value instanceof File) {
    const response = await pinFileToIpfs(value, { metadata });
    return `https://monegraph.mypinata.cloud/ipfs/${response.IpfsHash}/${value.name}`;
  }

  return value;
};

export default function ManageSeats() {
  const params = useParams<any>();
  const wallet = useWallet();
  const dashboardApi = useDashboardApi();
  const organizationId = get(params, 'id');
  const [visible, setVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState<any>(null);
  const urlsuffix = '/seats';
  const dashboard = useDashboardApi();
  const { contents: user } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));
  const [seats, setSeats] = useState(user?.organization?.users);

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FETCH_ORGANIZATION,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
          variables: {
            id: organizationId,
          },
        })
        .subscribe({
          next: function ({ data }: any) {
            if (data) {
              const organization = get(data, 'organizations[0]', null);

              if (organization) {
                const accounts = organization.accounts.map((account: any) => {
                  return {
                    seatWallet: account.id,
                    seatName: account.name,
                    isAdmin: account.permissions.organizationAdmin,
                    description: account.description,
                    avatar: account.avatar,
                  };
                });
                setOrganization({
                  ...organization,
                  accounts,
                });
              }
            }

            if (loading) {
              setLoading(false);
            }
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [setOrganization, wallet.account, loading]
  );

  const onEditSeat = (address: string) => {
    setVisible(true);
    setEditingIndex(address);
  };

  const onRemove = useCallback(
    async (address: string) => {
      await dashboardApi.fetchDashboard('DELETE', `${urlsuffix}/${address}`);

      const response = await fetch(`${process.env.REACT_APP_AWS_ENDPOINT}/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${dashboard.jwtToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();

      setSeats(result.data.organization.users);
    },
    [dashboard.jwtToken, dashboardApi]
  );

  const onModalClose = () => {
    setVisible(false);
    setEditingIndex(undefined);
  };

  const onFinish = useCallback(
    async (values: any) => {
      const avatar = await handleFileInput(values.avatar, { name: values.seatName, keyvalues: { type: 'avatar' } });

      const metadataHash = await pinJSONToIPFS(
        {
          bio: values.description ?? '',
          avatar,
        },
        {
          pinataMetadata: {
            name: `${values.seatWallet}.${organizationId}.description.json`,
          },
        }
      );

      console.log('metadataHash', metadataHash);

      const dashboardSeat = {
        name: values.seatName,
        permissions: {
          admin: values.isAdmin ? true : false,
          auctions: values.auctionsPermission ? true : false,
          mint: values.mintPermission ? true : false,
        },
        address: values.seatWallet,
        avatar: avatar ? avatar : null,
      };

      if (editingIndex) {
        await dashboardApi.fetchDashboard('PUT', `${urlsuffix}/${editingIndex}`, { ...dashboardSeat });
      } else {
        await dashboardApi.fetchDashboard('POST', `${urlsuffix}`, { ...dashboardSeat, organization: organizationId });
      }

      const response = await fetch(`${process.env.REACT_APP_AWS_ENDPOINT}/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${dashboard.jwtToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();

      setSeats(result.data.organization.users);

      setVisible(false);
      setEditingIndex(undefined);
    },
    [setVisible, setEditingIndex, editingIndex, organizationId, dashboardApi, dashboard.jwtToken]
  );

  useMemo(() => setSeats(user?.organization?.users), [user]);

  return (
    <>
      <Layout>
        <Row className="mb-3">
          <Col span={24}>
            <Card>
              <Typography.Title>Manage Seats</Typography.Title>
            </Card>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={23}>
            <Card>
              <Row justify="center" className="p-2">
                <Col xs={24} md={18} lg={16}>
                  <Spin spinning={loading}>
                    <List className="py-3" itemLayout="vertical">
                      {seats?.length ? (
                        seats.map((seat: any, index: number) => (
                          <Seat
                            key={seat.address}
                            seat={seat}
                            onEdit={() => onEditSeat(seat.id)}
                            onRemove={() => onRemove(seat.id)}
                          />
                        ))
                      ) : (
                        <Empty className="py-3" />
                      )}
                    </List>

                    <Button
                      type="dashed"
                      block
                      icon={<PlusOutlined />}
                      disabled={parseInt(organization?.accounts?.length) + 1 > organization?.seats}
                      onClick={() => setVisible(true)}>
                      Add seat
                    </Button>
                  </Spin>
                  <SeatFormModal
                    visible={visible}
                    onFinish={onFinish}
                    onCancel={onModalClose}
                    initialValues={find(seats, { id: editingIndex })}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <LoadingModal visible={dashboardApi.isLoading} />
      </Layout>
    </>
  );
}
