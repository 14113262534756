import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Card, Table, Spin, Button, Modal, Dropdown, Menu } from 'antd';
import { size } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import organizationAuctionBeacon from 'contracts/organization-auction-beacon';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import Layout from 'layout/AdminLayout';
import env from 'lib/env';

import { useDashboardApi, AccountsLoginSelector } from '../hooks/useDashboardApi';
import useDrafts, { Result } from '../hooks/useDrafts';

import { useAuctions } from './Auctions.state';
import useColumnDefinition from './components/ColumnDefinitions';
import TransferForm from './components/TransferForm';

export default function Auctions() {
  const wallet = useWallet();
  const [draftMap, setDraftMap] = useState<Result>();
  const dashboard = useDashboardApi();
  const draftApi = useDrafts();
  const { contents: account } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));
  const [state, , loading] = useAuctions(account?.organization?.legacyId?.toString()!);
  const columnDefinition = useColumnDefinition();
  const beacon = useContract(organizationAuctionBeacon, {
    address: env.MONEGRAPH_ORGAUCTIONS_FACTORY,
  });
  const executeMethod = useExecuteMethod();
  const history = useHistory();

  const hasState = !loading;
  const savedSize = size(draftMap);
  useEffect(() => {
    const request = async () => {
      const response = await draftApi.getAll();
      if (response) {
        setDraftMap(response);
      }
    };

    request();
  }, []);

  const activate = useCallback(async () => {
    await executeMethod(beacon.deployContract, [account.organization.legacyId], {
      from: wallet.account,
    });
  }, [beacon?.address, account?.organization?.legacyId, wallet.account]);

  const createAuction = useCallback(() => {
    if (hasState) {
      if (state.contract) {
        history.push(`/auction/create/${state.contract}`);
      } else {
        const hasAuctions = !!state.auctions.length;

        Modal.confirm({
          title: hasAuctions ? 'Auction Platform Upgraded' : 'Auction Platform',
          icon: <ExclamationCircleOutlined />,
          content: hasAuctions
            ? 'Click below to upgrade the auction platform for your organization'
            : 'Click below to activate the auction platform for your organization',
          okText: hasAuctions ? 'Upgrade' : 'Activate',
          cancelText: 'Later',
          onOk: activate,
        });
      }
    }
  }, [history, state?.contract, state?.auctions, hasState, activate]);

  const draftClick = useCallback(
    (item) => {
      history.push(`/auction/create/${state.contract}?draft=${item.key}`);
    },
    [savedSize, state?.contract]
  );

  const menu: JSX.Element[] = useMemo(() => {
    const userMenu: JSX.Element[] = [];
    const otherMenu: JSX.Element[] = [];
    if (draftMap) {
      for (const [id, value] of Object.entries(draftMap)) {
        if (Array.isArray(value)) {
          for (const draft of value) {
            userMenu.push(<Menu.Item key={draft.id}>{draft.name}</Menu.Item>);
          }
        } else {
          otherMenu.push(
            <Menu.ItemGroup key={id} title={value.name}>
              {value.drafts.map((draft) => (
                <Menu.Item key={draft.id}>{draft.name}</Menu.Item>
              ))}
            </Menu.ItemGroup>
          );
        }
      }
    }
    return [...userMenu, ...otherMenu];
  }, [draftMap]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Auctions</Typography.Title>
              </Col>
              <Col>
                {size(menu) ? (
                  <Dropdown.Button
                    type="primary"
                    className="btn-primary"
                    icon={<DownOutlined />}
                    overlay={
                      <Menu mode="vertical" onClick={draftClick}>
                        {menu}
                      </Menu>
                    }
                    onClick={createAuction}>
                    New Auction
                  </Dropdown.Button>
                ) : (
                  <Button type="primary" className="btn-primary" onClick={createAuction}>
                    {hasState && !state.contract && !state.auctions.length ? 'Activate' : 'New Auction'}
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Spin spinning={!hasState}>
                  <Table columns={columnDefinition} dataSource={hasState ? state.auctions : []} scroll={{ x: true }} />
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <TransferForm />
    </Layout>
  );
}
