/* eslint-disable react/no-multi-comp */
import { MouseEvent, useCallback } from 'react';

import { CopyOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row, Typography, Card } from 'antd';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import { ERC1155, ERC721 } from 'constants/collection';
import Layout from 'layout/AdminLayout';

import { AccountsLoginSelector, useDashboardApi } from '../hooks/useDashboardApi';

import { useWatchCollection } from './Tokens.state';
import BurnForm from './components/BurnForm';
import { PublicMintTable } from './components/PublicMintsTable';
import { TabNavigation } from './components/TabNavigation';
import { TokensTable } from './components/TokensTable';

export default function TokenList() {
  const wallet = useWallet();
  const params = useParams<any>();

  const dashboard = useDashboardApi();
  const { contents: account } = useRecoilValueLoadable(AccountsLoginSelector({ token: dashboard.jwtToken }));

  const [state, queryHash, loading] = useWatchCollection(
    wallet.account,
    params.contract,
    account.organization.legacyId
  );
  const { collection, contract } = loading ? { collection: [], contract: '' } : state;

  const copyAddress = useCallback(
    (event: MouseEvent<Element>) => {
      event.stopPropagation();
      copy(collection?.address);
    },
    [collection?.address]
  );
  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Typography.Title>{collection?.name ? `${collection?.name}'s Tokens` : 'Tokens'}</Typography.Title>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Descriptions title="" bordered layout="vertical">
                  <Descriptions.Item label="Name">{collection?.name}</Descriptions.Item>
                  <Descriptions.Item label="Symbol">{collection?.symbol}</Descriptions.Item>
                  <Descriptions.Item label="Address">
                    <Button type="link" href={`https://etherscan.io/address/${collection?.address}`}>
                      {collection?.address}
                    </Button>
                    <CopyOutlined onClick={copyAddress} />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row className="pt-3">
              <TabNavigation
                routeEnding="tokens"
                basePath={'/collections/:contract/:tab'}
                tabs={[
                  {
                    tabName: 'Tokens',
                    tabPath: 'tokens',
                    tabBody: <TokensTable collection={collection} contract={contract} />,
                  },
                  ...((collection?.type === ERC1155 && collection?.version === '2') ||
                  (collection?.type === ERC721 && collection?.version === '3')
                    ? [
                        {
                          tabName: 'Public Mints',
                          tabPath: 'public-mints',
                          tabBody: <PublicMintTable collection={collection} />,
                        },
                      ]
                    : []),
                ]}
              />
            </Row>
          </Card>
        </Col>
      </Row>
      <BurnForm hash={queryHash} />
    </Layout>
  );
}
