import { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useWallet } from 'use-wallet';

import { useDashboardApi } from 'pages/admin/hooks/useDashboardApi';

import { ColumnsDefinitionsProps } from './ColumnsDefinition';

interface DeleteButtonProps extends ColumnsDefinitionsProps {
  record: any;
}

export default function DeleteButton({ record, setOrganizations, organizations }: DeleteButtonProps): JSX.Element {
  const wallet = useWallet();
  const dashboardApi = useDashboardApi();

  const deleteOrganization = useCallback(async () => {
    await dashboardApi.fetchDashboard('DELETE', `/organizations/${record.id}`, {});
    const list = organizations?.filter((item) => item.id !== record.id);
    setOrganizations(list!);
  }, [record.id, wallet.account]);

  return (
    <Popconfirm
      title={`Are you sure to delete ${record.name}?`}
      onConfirm={deleteOrganization}
      okText="Yes"
      cancelText="No">
      <Button icon={<DeleteOutlined />} danger type="primary">
        Delete
      </Button>
    </Popconfirm>
  );
}
